@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;800&display=swap');
body{
	font-family: Dosis,sans-serif;
}
ul {
    margin: 0px;
    padding: 0px;
}
.top-nav{
	display:flex;
	justify-content:space-between;
	background-color: #242c42;
	color:#fff;
	padding:0.5rem 1rem;
}
.top-nav a{
	margin-left:12px;
	border-left: 1px solid #fff;
	padding-left: 12px;
}
.scn-navbar-brand{
	font-size: 34px;
	font-weight: bold;
}
.footer-section {
  background: #fff;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #8a8a8a;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #3c297c;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #3c297c;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #000;
  line-height: 28px;
}
.footer-social-icon span {
  color: #3c297c;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  border-radius:30%;
  font-size: 20px;
  padding:6px;
  margin-right: 15px;
  border: 3px solid #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
}
.facebook-bg{
  background: #3fdbda;
}
.instagram-bg{
  background: #f5c30a;
}
.youtube-bg{
  background: #f25a57;
}
.footer-widget-heading h3 {
  color: #3c297c;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #00c1cf;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: #00c1cf;
}
.footer-widget ul li a {
  color: #000;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  border: 1px solid #00c1cf;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #00c1cf;
    padding: 13px 20px;
    border: 1px solid #00c1cf;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #3c297c;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}
.copyright-text p a{
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #fff;
}
.nav-item{
	margin-left:auto;
	margin-right:auto;
}

/*Home.js*/
.feature-cards .card{
	text-align:center;
	margin-top:20px;
	border:0px;
	transition: all .4s ease-in-out;
	border-radius: 25px;
	box-shadow: 0 1px 24px 0 rgb(0 0 0 / 10%);
	padding: 30px;
	color:#3c297c;
	font-weight:600px;
}

.feature-cards .card:hover {
	background-color:#2eb9d6;
	color: #fff;
	transform: rotate(1turn);
}
.feature-cards .card img{
	transition: all .4s ease-in-out;
	border-radius:50%;
}
.feature-cards .card img:nth-child(1){
	display:block;
	transform: rotate(1turn);
}
.feature-cards .card img:nth-child(2){
	display:none;
	-webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
	transform: rotate(1turn);
}
.feature-cards .card:hover img:nth-child(2){
	display:block;
	-webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
	transform: rotate(1turn);
}
.feature-cards .card:hover img:nth-child(1){
	display:none;
	-webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
	transform: rotate(1turn);
}
.yellow-bg{
	background-repeat: no-repeat;
	background-position: center;
	background-size:cover;
	margin-top:100px;
	color:#fff;
}
.yellow-bg img.img-thumbnail:hover{
	transform: scale(2);
	transition: 0.5s;
}
.staff .col-sm-3{
	background-color:#f2f2f2;
	padding: 25px;
}
.staff .col-sm-3 span{
	display:inline-block;
	margin-top:10px;
}
.yellow-bg .col-sm-3{
	margin-bottom:10px;
}

/*Global*/
.feature-underline{
	width:100px;
	height:5px;
	background:#2eb9d6;
	display:block;
	margin-top:30px;
	margin-bottom:50px;
}






@media only screen and (max-width: 600px) {
  .scn-navbar-brand{
	  display:none;
  }
}